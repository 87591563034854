<template>
  <div>
    <v-layout
      v-if="show && type === 'loading'"
      justify-center
      align-center
      :style="{position: 'fixed',top: 0,bottom: 0,left: 0,right: 0,'z-index': zIndex,background: 'black',opacity: .46}"
    >
    </v-layout>
    <v-snackbar
      v-model="show"
      :color="backgroundColor"
      right
      :timeout="time * 1000 || -1"
      :style="{'z-index': zIndex + 1}"
    >
      <span class="white--text" style="display: flex;align-items: center;">
        <v-progress-circular
          v-if="type === 'loading'"
          :width="3"
          color="white"
          indeterminate
          :size="18"
          style="margin-right: 12px;"
        ></v-progress-circular>
        <v-avatar color="white" v-if="!!type && (type !== 'loading')" size="24" style="margin-right: 18px;">
          <v-icon color="primary" v-if="type === 'info'">
            mdi-information
          </v-icon>
          <v-icon color="success" v-if="type === 'success'">
            mdi-check-circle
          </v-icon>
          <v-icon color="error" v-if="type === 'error'">
            mdi-close-circle
          </v-icon>
          <v-icon color="warning darken-1" v-if="type === 'warning'">
            mdi-alert-circle
          </v-icon>
        </v-avatar>
        <div style="text-align: left;">{{ text }}</div>
      </span>
      <div style="margin-left: 24px;text-align: right;text-align: right">
        <v-icon
          size="18"
          v-if="type !== 'loading'"
          dark
          fab
          @click="show = false"
        >
          mdi-close
        </v-icon>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    zIndex: {
      type: Number,
      default: 3000
    }
  },
  data () {
    return {
      show: false,
      fresh: false,
      text: '',
      type: '',
      time: 0,
      next: {}
    }
  },
  methods: {
    snackbarChange (val) {
      let text = val.text || ''
      let type = val.type || 'info'
      let time = val.type === 'loading' ? 0 : val.time >= 0.5 ? val.time : 5

      let waittime = 0
      if (!!text) {
        if (this.fresh) {
          this.next = { text, type, time }
        } else {
          let waittime = 0
          if (this.show) {
            this.show = false
            waittime = 500
          }
          this.text = text
          this.type = type
          this.time = time
          setTimeout(() => {
            this.show = true
          }, waittime)
        }
      } else {
        this.next = {}
        this.show = false
      }
    }
  },
  watch: {
    'info': {
      deep: true,
      handler (val) {
        this.snackbarChange(val)
      }
    },
    'show' (val, oVal) {
      if (val !== oVal) {
        if (val) {
          this.fresh = true
          setTimeout(() => this.fresh = false, 500)
        }
      }
    },
    'fresh' (val, oVal) {
      if (!val && oVal && !!this.next.text) {
        this.show = false

        this.fresh = true
        this.text = this.next.text
        this.type = this.next.type
        this.time = this.next.time
        this.next = {}
        setTimeout(() => this.show = true, 500)
      }
    }
  }
}
</script>

<style>

</style>
